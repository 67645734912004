import { template as template_ce86c2d3523a49e3a4612b2985565dc7 } from "@ember/template-compiler";
const FKLabel = template_ce86c2d3523a49e3a4612b2985565dc7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
