import { template as template_db7994ce7b474890bc0b55abec9fb9a2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_db7994ce7b474890bc0b55abec9fb9a2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
